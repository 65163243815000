@font-face {
  font-family: 'Danley';
  src: url('../fonts/Danley.woff2') format('woff2'),
       url('../fonts/Danley.woff') format('woff'),
       url('../fonts/Danley.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Danley-ExtraBold';
  src: url('../fonts/Danley-ExtraBold.woff2') format('woff2'),
       url('../fonts/Danley-ExtraBold.woff') format('woff'),
       url('../fonts/Danley-ExtraBold.otf') format('opentype');
  font-display: swap;       
}

@font-face {
  font-family: 'Danley-Bold';
  src: url('../fonts/Danley-Bold.woff2') format('woff2'),
       url('../fonts/Danley-Bold.woff') format('woff'),
       url('../fonts/Danley-Bold.otf') format('opentype');
  font-display: swap;       
}

body {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  background-color: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: #183041;
}

body.countdown-margin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#cloud-intro{
  position: relative;
  min-height: 100vh;
  background: linear-gradient(180deg, #F0F7FF 0%, #F9F9F9 100%);
  opacity: 0.95;
}
@keyframes wind{
  0% {
    background-position: 0 200px, 0 300px, 100px 250px;
  }
  100% {
    background-position: 1000px 200px, 1200px 300px, 1100px 250px;
  }

}

h1, h2, h3, h4 {
  text-transform: capitalize;
}

h2.small-margin {
  margin-bottom: 20px !important;
}

.row.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

div:focus {
  outline: none;
}

input {
  color: #183041;
}

button {
  font-family: 'Muli', sans-serif;
  transition: all .2s ease-in-out;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  border: none;
}

button:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {
  min-height: 100vh;
}

#page {
  /*position: relative;*/
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.offcanvas #page {
  overflow: hidden;
  position: absolute;
}

h1 {
  font-family: OPTIDanley-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 110%;
  /* or 53px */

  text-align: center;

  color: #183041;
}

h1, h2, figure {
  color: #183041;
  margin: 0 0 20px 0;
  font-family: 'Danley', serif;
}

h2, h3, h5 {
  color: rgba(24, 48, 65, 0.8);
}

h2 {
  text-align: center;
}

h3 {
  font-style: normal;
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
}

.signin-main-header {
  font-size: 28px;
}

h4 {
  margin: 0 0 20px 0;
  font-family: 'Muli', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

h5 {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;

  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

h5.lower {
  text-transform: none;
}

h6 {
  font-size: 18px;
  font-weight: bold;
  line-height: 140%;
  color: #465967;
  margin-bottom: 0;
  margin-top: 2px;
  text-align: left;
}

p {
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: 140%;
  color: rgba(24, 48, 65, 0.8);
}

p.full-color {
  color: rgb(24, 48, 65);
}

p.invalid {
  color: #F01212;
}

.products-modal-btn-container {
  width: 100%;
  text-align: left;
  margin-top: -10px;
}

.products-modal-btn {
  background: #0074D2;
  border-radius: 8px;
  height: 48px;
  width: 220px;
  text-align: center;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  color: #F9F9F9;
  margin: 20px auto;
  border: none;
  cursor: pointer;
}

.products-modal-btn:hover {
  background: #00579E;
}

.products-modal-btn:active {
  background: #1F90FE;
}

.products-modal-btn:disabled {
  background: #949494;
  cursor: default;
}

.modal-container,
.survey-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.60);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  transition: opacity 1s ease-in;
}

.modal-container.interview-question-modal-container {
  z-index: 10001;
}

.survey-modal-container {
  display: block;
  overflow-y: scroll;
}

.survey-modal-container > .signin-modal-body {
  margin-left: auto;
  margin-right: auto;
}

.modal-container.dark {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-body {
  background-color: #FFF;
}

.modal-title {
  font-weight: bold;
  line-height: 20px;
  color: #465967;
}

.modal-container.transparent {
  background-color: rgba(255,255,255, 0.9);
}

.modal-container.transparent > .modal-dialog {
  width: 330px;
}

.modal-container.transparent > .modal-dialog,
.interview-question-modal {
  width: 357px;
  min-height: 200px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #DFE0EB;
  padding: 16px 20px;
  position: relative;
  pointer-events: auto !important;
  box-shadow: 0px 0px 15px rgba(85, 85, 85, 0.15);
}

.interview-question-modal {
  width: 460px;
  max-width: 95vw;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 10001;
  max-height: 95vh;
  overflow-y: auto;
}

.modal-container.light,
.survey-modal-container {
  background-color: #fff;
}

.dialog-title-text {
  margin-top: 20px;
  font-size: 28px;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}

.alert-fixed-bottom {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;
}

.link {
  color: #0074D2;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .alert-fixed-bottom {
    bottom: 60px;
  }
  #countdown-alert {
    font-size: 14px;
    min-height: 39px
  }
  h1 {
    font-size: 38px;
  }

  .landing-top-section {
    height: 480px;
    border: none;
  }
  .fb_dialog_mobile {
      margin-bottom: 60px;
  }
}
@media screen and (max-width: 374px) {
  #countdown-alert{
    font-size: 12px;
  }
}

.dialog-body {
  display: flex;
  flex-direction: column;
  height: 90%;
}

.nav-logo {
  height: 35px;
  padding-left: 15px;
}

.landing-header > .nav-logo {
  margin-top: 24px;
}

.spinner-grow {
  margin-bottom: 6px;
}

.back-arrow {
  cursor: pointer;
}

.back-arrow:hover {
  opacity: 0.5;
}

hr {
  border-bottom: 1px solid rgba(255,255,255,0.05);
}

.no-cloud {
  background: #F7F8FC;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.no-cloud-white {
  background: #ffffff;
}

.info-icon {
  padding: 4px;
  cursor: pointer;
}

.info-icon:hover {
  opacity: 0.5;
}

.btn-add {
  background: #007ced;
  color: #fff;
  border-radius: 25px;
  opacity: 1 !important;
}

.btn-add:hover {
  color: #cce7ff;
}

.span-link {
  font-weight: bold;
  color: #007ced;
  cursor: pointer;
}

.order-prediction-header {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Title Text */

  color: #183041;
}

.order-prediction-header.recommendation {
  font-family: 'Amaranth', sans-serif;
  font-weight: normal;
  font-size: 28px;
}

.order-prediction-header-container {
  max-width: 95%;
  width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}

.order-prediction-subheader {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  text-align: center;

  /* Title Text */
  padding: 20px 12px;

  color: #183041;
}

.input-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 515px;
}

.input-wrapper.account-modal {
  margin-top: -8px !important;
}

.input-wrapper > *,
.modal-input-container  > *,
.checkout-input-container  > * {
  display: block;
  text-align: left;
}

.modal-input-container,
.checkout-input-container {
  margin: 4px;
  display: inline-block;
  max-width: 100%;
}

@media screen and (max-width: 550px) {
  .checkout-input-container {
    width: 98%;
    margin: 1%;
  }
  .checkout-input-container.half {
    width: 48%;
  }
}

.modal-input-container.full {
  width: 100%;
  max-width: 415px;
}

.modal-input-container.full.sd-mui-checkout {
  max-width: 400px;
}

.modal-input,
.stripe-input {
  background: #EDEFF2;
  border: 1px solid #DFE0EB;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  padding-left: 8px;
  color: rgba(24, 48, 65, 0.8);
  width: 200px;
  height: 40px;
}

.modal-label {
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;

  color: rgba(24, 48, 65, 0.8);
}

.input-wrapper > input,
.modal-input-container > input,
.modal-input-container > textarea,
.modal-input-container > select {
  width: 200px;
  max-width: 100%;
  height: 40px;
  border-radius: 8px;
}

.input-wrapper > .invalid-input,
.modal-input-container > .invalid-input {
  border: 1px solid red;
}

.sd-modal-footer {
  margin-top: 20px;
}

#countdown-alert {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  text-align: center;
  color: #465967;
  font-weight: bold;
}

#countdown-alert.countdown-space-top {
  top: 10px;
}

.padding-top-delivery {
  padding-top: 39px;
}
.padding-top-delivery-sm {
  padding-top: 36px;
}
.position-top-delivery {
  top: 39px;
}

.covid-alert {
  background-color: #FFD8BC;
  text-align: left;
  font-size: 14px;
  padding: 16px !important;
  display: flex;
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  margin-bottom: 60px !important;
  cursor: pointer;
  transition: all .2s linear;
}

.covid-notification {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  max-width: 80%;
  line-height: 140%;
  margin: -52px auto 52px;
}

.covid-alert > .checkbox-container {
  margin-right: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.checkbox {
   background-color: #EDEFF2;
   border: 2px solid #465967;
   box-sizing: border-box;
   text-align: center;
   cursor: pointer;
   transition: all .2s linear;
   width: 33px !important;
   height: 33px !important;
   border-radius: 8px;
}

.checkbox:active, .checkbox:active > * {
  transform: scale(.9);
}

.checkbox.checked {
  background-color: #49CB93;
}

.checkmark-white {
  height: 16px;
  margin-top: 6px;
}

.error-message-container {
  margin-top: -20px;
  padding-bottom: 20px;
}

.error-message {
  color: #F01212;
  font-size: 14px;
  line-height: 140%;
}

.manifest-add-btn {
  margin-top: 4px;
}
.interview-modal-img {
  text-align: center;
}
.interview-modal-img > img {
  height: 100px;
  width: auto;
}
.summary-subtotal-text,
.summary-total-text {
  text-align: left;
}

.hiw-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 12px;
}
.hiw-number {
  height: 32px;
  width: 32px;
  color: #F9F9F9;
  background-color: #3E8CDF;
  border-radius: 25px;
  padding-top: 4px;
  margin-right: 15px;
}
.hiw-response {
  text-align: left;
  padding-top: 4px;
  color: #465967;
}
.hiw-container {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}
#hiw-header {
  margin-top: 60px;
  margin-bottom: 20px;
}
#gate-zip-input {
  width: 120px;
}
.interview-enter-text-container {
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 43px;
}
.green-check {
  color: #00A664;
  padding-right: 4px;
}

.x-container {
  width: 20px;
  height: 20px;
}

.summary-product-price {
  font-size: 16px;
  font-weight: bold;
}

#employer-field {
  padding-left: 8px;
  padding-top: 10px;
}

.pill-button {
  border-radius: 25px !important;
  text-transform: none !important;
  padding: 0px 8px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-top: -12px;
  height: 45px;
  width: 124px;
}

.subtext {
  font-size: 9px;
  line-height: 100%;
}

#delivery-zipcode-input {
  width: 90px;
  background: #FFF;
}

.countdown-alert-submit-btn {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

#outage-banner {
  z-index: 1;
}

#fireworks-container {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100001;
}

.checkout-summary-padding {
  padding: 0 20px;
}

#testimonial-wrapper {
  height: 160px;
}

#snackbar-discount {
  position: absolute;
  top: 0;
  bottom: 100px
}