.fixed-continue-btn-container {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100vw;
    height: 90px;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.0490829), 0px -15px 35px rgba(43, 64, 78, 0.095505);
    z-index: 10;
}

.fixed-continue-btn-container.with-ref-terms {
    height: 150px;
}

.fixed-continue-btn-container.with-terms {
    height: 120px;
}

.fixed-continue-btn {
    max-width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.fixed-terms-and-privacy {
  font-family: 'Muli', sans-serif;
  font-size: 10px;
  line-height: 140%;
  font-weight: normal;
  max-width: 225px;
  margin-top: 10px;
}

.fixed-terms-and-privacy > span {
  text-decoration: underline;
  cursor: pointer;
}

.fixed-terms-and-privacy span:hover {
  text-decoration: underline;
}

.fixed-continue-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 4px;
    margin: auto 10px;
}

.fixed-continue-col > .interview-continue-button {
    margin: 0 0 0 12px !important;
}

@media screen and (max-width: 320px) {
    .fixed-continue-col > .interview-continue-button {
        margin: 0 0 0 0px !important;
    }
}

.fixed-continue-subtotal {
    font-family: 'Muli', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #465967;
    cursor: pointer;
}

@media screen and (max-width: 320px) {
    .fixed-continue-subtotal {
        max-width: 90%;
    }
}

.fixed-continue-subtotal:hover {
    text-decoration: underline;
}

@media screen and (max-width: 991px) {
    .fixed-continue-btn {
        justify-content: center;
    }
}
